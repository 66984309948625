<template>
  <div class="d-flex">
    <label for="searchInput" class="icon-search p-0" @click="toggleModal()">
      <span class="d-none">{{ headline }}</span>
    </label>
    <div class="search--overlay" :class="{ showModal: showModal }">
      <button
        type="button"
        id="whitespaceClose"
        class="d-none d-lg-block"
        @click="toggleModal()"
      >
        <span class="d-none">
          {{ closeText }}
        </span>
      </button>
      <div class="search--overlay--inner shadow-lg">
        <header class="search--overlay-header">
          <h6 class="mb-4">
            {{ $t('Search') }}
          </h6>
          <button type="button" @click="toggleModal()">
            <i class="icon-x">
              <span class="d-none">
                {{ closeText }}
              </span>
            </i>
          </button>
        </header>
        <div class="search--overlay-body">
          <h6>{{ headline }}</h6>
          <form
            @submit.prevent="changeHandler({ gtm: false })"
            class="form_search"
            name="form_search"
          >
            <div class="form-group search-group">
              <input
                autocomplete="off"
                v-model="query"
                type="text"
                id="searchInput"
                :placeholder="$t('Search')"
                @change="searchHandler(query)"
                ref="searchInput_Value"
              />
              <button>
                <i class="icon-search"></i>
                <span class="d-none">{{ $t('Search') }}</span>
              </button>
            </div>

            <div v-if="searchTitles.length" class="container-full">
              <aside class="search--overlay-body-dropdown">
                <ul class="box bg-white py-2 shadow-sm blanklist">
                  <template v-for="(data, index) in searchTitles">
                    <li v-if="data" :key="index">
                      <nuxt-link
                        v-if="data.type === 'product'"
                        :to="data.link"
                        class="d-block"
                        @click.native="sendAnalyticsToGTM(data)"
                      >
                        {{ data.title }}
                      </nuxt-link>
                      <button @click="popularSearchHandler(data)" v-else>
                        {{ data }}
                      </button>
                    </li>
                  </template>
                </ul>
              </aside>
            </div>

            <ul class="blanklist">
              <li class="mb-4">
                <strong>{{ $t('Popular Searches') }}</strong>
              </li>
              <li
                v-for="popularSearch in popularSearches"
                :key="popularSearch.keyword"
              >
                <button @click="popularSearchHandler(popularSearch.keyword)">
                  {{ popularSearch.keyword }}
                </button>
              </li>
            </ul>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useContext, ref, computed } from '@nuxtjs/composition-api';
import { useMainNavStore } from '~/stores/mainNavStore';
import { Logger } from '~/helpers/logger';

export default {
  name: 'SearchOverlay',

  setup() {
    const mainNavStore = useMainNavStore();
    const { app } = useContext();
    const { i18n } = app;
    const locale = i18n.localeProperties.code;

    const query = ref('');
    const showModal = computed(() => mainNavStore.showModal);
    const searchInput_Value = ref(null);
    let sendOnce = false;

    const changeHandler = async ({ gtm } = { gtm: true }) => {
      mainNavStore.showModal = false;
      const searchRoute = app.localeRoute({
        name: 'search',
        params: { search: locale == ('de' || 'de_ch') ? 'suche' : 'search' },
        query: {
          q: query.value,
        },
      });

      if (!sendOnce && gtm) {
        try {
          app.$gtm.push({ ecommerce: null });
          app.$gtm.push({
            event: 'selected_popular',
            ecommerce: {
              search_item: query.value,
              search_link: searchRoute.fullPath,
            },
          });
          sendOnce = true;
        } catch (e) {
          Logger.error('searchResultSelected/gtm', e);
        }
      }

      window.location.href = searchRoute.fullPath;
    };

    const toggleModal = () => {
      if (showModal.value) {
        document.body.classList.remove('overflow-hidden');
      } else {
        document.body.classList.add('overflow-hidden');
        searchInput_Value.value.focus();
      }
      mainNavStore.showModal = !showModal.value;
    };

    return {
      app,
      mainNavStore,
      showModal,
      query,
      changeHandler,
      popularSearches: [],
      searchTitles: computed(() =>
        query.value ? mainNavStore.searchTitles : [],
      ),
      timeout: null,
      toggleModal,
      searchInput_Value,
      sendOnce,
    };
  },
  props: {
    placeholder: String,
    headline: String,
    popularSearchesHeadline: String,
    closeText: String,
  },
  watch: {
    query() {
      this.debounceSearch();
    },
  },
  methods: {
    sendAnalyticsToGTM(item) {
      try {
        this.app.$gtm.push({
          event: 'search_product_selected',
          ecommerce: {
            result_product_selected: item.title,
            result_product_link: item.link,
          },
        });
      } catch (e) {
        Logger.error('searchProductSelected/gtm', e);
      }
    },

    debounceSearch() {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.searchHandler(this.query);
      }, 1000); // delay
    },

    async getPopularSearch() {
      const locale = this.$i18n.localeProperties.code;
      await this.mainNavStore.getPopularSearch(locale);
      this.popularSearches = this.mainNavStore.popularSearchesData;
    },
    async searchHandler(query) {
      this.$refs.childComponentRef?.resetFilter();
      const locale = this.$i18n.localeProperties.code;
      await this.mainNavStore.suggestionHandler(locale, query);
    },

    popularSearchHandler(key) {
      this.query = key;
      this.changeHandler();
    },
  },
  async created() {
    this.getPopularSearch();
  },
};
</script>

<style scoped lang="scss">
@import 'assets/styles/misc/vars';

body.overflow-hidden {
  position: relative;
  overflow: hidden;
}

.icon-search {
  position: relative;
  font-size: 1.5rem;
  color: $hazelgreen;

  &::before {
    position: relative;
    z-index: 1;
  }

  &::after {
    content: '';
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 2px);
    transform: translate(-50%, -50%) scale(0.5);
    left: 50%;
    background: $bone-83;
    z-index: 0;
    transition: $transition-base;
    opacity: 0;
  }

  &:hover {
    &::after {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
  }
}

.search--overlay {
  position: fixed;
  z-index: 9992;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
  top: 0;
  right: 0;
  transform: translateX(100%);
  transition: transform 0.2s ease-out;

  &.showModal {
    transform: translateX(0);
  }

  &--inner {
    background: $sand;
    padding: 2.5rem 1rem;
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    .search--overlay-header {
      button {
        padding: 1rem !important;
        position: absolute;
        right: 0;
        top: 0;

        i {
          &::before {
            font-size: 1.5rem;
            transition: all 0.4s ease;
          }
        }

        &:hover {
          i {
            &::before {
              transform: rotate(180deg);
              color: $hazelgreen;
            }
          }
        }
      }
    }

    .search--overlay-body {
      form {
        .form-group.search-group {
          margin-bottom: 4rem;

          input {
            width: 100%;
            max-width: initial;
            &:focus-visible {
              outline-color: $primary;
            }
          }

          ~ .container-full {
            ~ .blanklist {
              margin-top: 1rem;
            }
          }
        }
      }

      h6,
      .h6 {
        margin-bottom: 2rem;
      }

      ul:not(.box) {
        display: flex;
        flex-direction: column;

        li:not(.mb-4) {
          padding: 0;
          margin: 0.5rem 0 !important;

          button {
            padding: 0 !important;
            font-weight: 400 !important;
          }

          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        @include media-breakpoint-between(lg, xl) {
          margin: 0;
        }
      }

      &-dropdown {
        position: relative;

        .box {
          display: flex;
          flex-direction: column;
          margin-top: -3.5rem;
          border-radius: $border-radius;
          position: absolute;
          width: 100%;

          li {
            margin: 0;
            padding: 0;

            button,
            a {
              padding: 1rem;
              width: 100%;
              text-align: left;
              font-weight: 500;
              color: $anthracite;
              font-size: 1rem !important;
            }

            &:hover {
              button,
              a {
                &:after,
                &::before {
                  display: none;
                }
                color: $primary;
                background: $jasmine-95;
              }
            }

            &::after {
              display: none !important;
            }
          }
        }
      }
    }

    @include media-breakpoint-up(lg) {
      padding: 2.5rem;
    }
  }

  @include media-breakpoint-up(lg) {
    #whitespaceClose {
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      cursor: inherit;
    }

    &--inner {
      width: 50%;
      max-width: 50%;
      margin-left: auto;
    }
  }

  @include media-breakpoint-up(xxl) {
    #whitespaceClose {
      width: 66.6%;
    }

    &--inner {
      width: 33.3%;
      max-width: 33.3%;
    }
  }
}

.filter__buttons {
  gap: 1rem;
}
</style>
